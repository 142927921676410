<template>
  <div class="card shadow-sm">
    <form id="formItems" class="needs-validation" novalidate>
      <div class="card-header">
        <h3 class="card-title" style="font-size: 20px; font-weight: bold">
          เพิ่มงวดบัญชี
        </h3>
      </div>
      <!-- form -->
      <div class="card-body">
        <div class="row d-flex justify-content-center mb-3">
          <div class="col-sm-10">
            <label for="validationTooltip01" class="required form-label"
              >งวดที่</label
            >
          </div>
          <div class="col-sm-10">
            <input
              v-model="form.installment"
              type="text"
              class="form-control"
              id="validationTooltip01"
              required
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
        </div>

        <div class="row d-flex justify-content-center">
          <div class="col-sm-5">
            <label for="validationTooltip01" class="required form-label"
              >วันที่เริ่มงวด</label
            >
            <input
              v-model="form.startDate"
              type="date"
              class="form-control"
              id="validationTooltip01"
              required
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>

          <div class="col-sm-5" id="margin-text">
            <label for="validationTooltip01" class="required form-label"
              >วันที่สิ้นงวด</label
            >
            <input
              v-model="form.endDate"
              type="date"
              class="form-control"
              id="validationTooltip01"
              required
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
        </div>
      </div>

      <hr class="pt-0 mt-0" style="color: LightGrey" />

      <footer class="m-6">
        <div class="card-toolbar">
          <button
            type="submit"
            class="btn btn-sm btn-light btn-primary me-2"
            style="font-size: 14px; font-weight: bold"
            @click="submit"
          >
            บันทึก
          </button>
          <button
            type="button"
            class="btn btn-sm btn-light"
            style="font-size: 14px; font-weight: bold"
            @click="cancel"
          >
            ยกเลิก
          </button>
        </div>
      </footer>
      <!-- form -->
    </form>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";

export default {
  data: () => ({
    classValid: "",
    isSubmit: false,
    form: {
      installment: "",
      startDate: "",
      endDate: "",
    },
  }),

  methods: {
    async submit() {
      await this.validateForm();
      this.isSubmit = true;
      // Swal.fire({
      //   icon: "success",
      //   title: "เพิ่มสำเร็จ",
      //   toast: true,
      //   position: "top-end",
      //   showConfirmButton: false,
      //   timer: 2000,
      //   timerProgressBar: true,
      // }).then(() => {
      //   this.$router.go(-1);
      // });
    },
    validateForm() {
      let forms = document.querySelectorAll(".needs-validation");
      Array.prototype.slice.call(forms).forEach(function (form) {
        form.addEventListener(
          "submit",
          function (event) {
            event.preventDefault();
            if (!form.checkValidity()) {
              event.stopPropagation();
            }
            form.classList.add("was-validated");
          },
          false
        );
      });
    },
    cancel() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped src="@/assets/custom-outside.css"></style>
